import * as React from 'react';
import Layout from '../components/layout';

export default function Error() {
    return(
        <Layout>
            <h1>Checkout error</h1>
            <p>Something has gone wrong with my secure online checkout.</p>
            <p>Please try again, and if the error persists, <strong>definitely</strong> contact me because this is all done in house, baby.</p>
        </Layout>
    );
}